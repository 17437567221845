<template>
  <div>
    <b-col v-if="!client" cols="12" md="7" class="mx-auto mt-4">
      <b-alert :show="!client" variant="danger">
        <h4 class="alert-heading">{{ errorMessage.title }}</h4>
        <div class="alert-body">
          {{ errorMessage.message }}. Check
          <b-link class="alert-link" :to="{ name: 'client-list' }">
            Clients List
          </b-link>
          for other clients.
        </div>
      </b-alert>
    </b-col>

    <div class="mt-2" v-if="client">
      <b-col cols="12" md="10" class="mx-auto">
        <b-card>
          <b-row>
            <!-- User Info: Left col -->
            <b-col
              cols="12"
              xl="7"
              class="d-flex justify-content-between flex-column"
            >
              <!-- User Avatar & Action Buttons -->
              <div class="d-flex justify-content-start align-items-center">
                <b-avatar
                  :src="client.logo"
                  :text="avatarText(client.name)"
                  :variant="client.logo ? `white` : `light-primary`"
                  size="104px"
                  rounded
                />
                <div class="d-flex flex-column ml-1">
                  <div class="mb-1">
                    <h1 class="mb-0">
                      {{ client.name }}
                    </h1>
                    <!-- <span class="card-text">{{ client.email }}</span> -->
                  </div>
                  <div class="d-flex flex-wrap"></div>
                </div>
              </div>

              <!-- User Stats -->
              <div class="d-flex align-items-center mt-2">
                <div class="d-flex align-items-center mr-2">
                  <b-avatar variant="light-primary" rounded>
                    <feather-icon icon="UsersIcon" size="18" />
                  </b-avatar>
                  <div class="ml-1">
                    <h5 class="mb-0">
                      {{
                        getFormatedValue(
                          client.analytics.google.users.value,
                          client.analytics.google.users.type
                        )
                      }}
                    </h5>
                    <small>Users</small>
                  </div>
                </div>

                <div class="d-flex align-items-center mr-2">
                  <b-avatar variant="light-primary" rounded>
                    <feather-icon icon="DatabaseIcon" size="18" />
                  </b-avatar>
                  <div class="ml-1">
                    <h5 class="mb-0">
                      {{
                        getFormatedValue(
                          client.analytics.google.sessions.value,
                          client.analytics.google.sessions.type
                        )
                      }}
                    </h5>
                    <small>Sessions</small>
                  </div>
                </div>

                <div class="d-flex align-items-center mr-2">
                  <b-avatar variant="light-primary" rounded>
                    <feather-icon icon="TrendingUpIcon" size="18" />
                  </b-avatar>
                  <div class="ml-1">
                    <h5 class="mb-0">
                      {{
                        getFormatedValue(
                          client.analytics.google.bounceRate.value,
                          client.analytics.google.bounceRate.type
                        )
                      }}
                    </h5>
                    <small>Bounce Rate</small>
                  </div>
                </div>

                <div class="d-flex align-items-center">
                  <b-avatar variant="light-primary" rounded>
                    <feather-icon icon="ClockIcon" size="18" />
                  </b-avatar>
                  <div class="ml-1">
                    <h5 class="mb-0">
                      {{
                        getFormatedValue(
                          client.analytics.google.avgSessionDuration.value,
                          client.analytics.google.avgSessionDuration.type
                        )
                      }}
                    </h5>
                    <small>Session Duration</small>
                  </div>
                </div>
              </div>
            </b-col>

            <!-- Right Col: Table -->
            <b-col cols="12" xl="5">
              <table class="mt-2 mt-xl-0 w-100">
                <tr>
                  <th class="pb-50">
                    <feather-icon icon="UserIcon" class="mr-75" />
                    <span class="font-weight-bold">Slug</span>
                  </th>
                  <td class="pb-50">
                    {{ client.slug }}
                  </td>
                </tr>
                <tr>
                  <th class="pb-50">
                    <feather-icon icon="CheckIcon" class="mr-75" />
                    <span class="font-weight-bold">Status</span>
                  </th>
                  <td class="pb-50 text-capitalize">
                    <b-badge
                      pill
                      :variant="`light-${resolveClientStatusVariant(
                        client.isActive
                      )}`"
                      class="text-capitalize"
                    >
                      {{ client.isActive ? "Active" : "Inactive" }}
                    </b-badge>
                  </td>
                </tr>
              </table>

              <div>
                <b-badge class="text-white d-block mt-1" variant="primary">
                  <div class="d-flex align-items-center">
                    <feather-icon
                      size="30"
                      class="calendarIcon"
                      icon="CalendarIcon"
                    ></feather-icon>
                    <flat-pickr
                      v-model="rangePicker"
                      :config="{ mode: 'range' }"
                      class="
                        form-control
                        flat-picker
                        bg-transparent
                        border-0
                        shadow-none
                        text-white
                      "
                      placeholder="YYYY-MM-DD"
                    />
                    <b-button @click="getData" variant="dark">
                      Update Dates
                    </b-button>
                  </div>
                </b-badge>
              </div>
            </b-col>
          </b-row>
        </b-card>
        <b-card>
          <b-table
            :busy="isFetchingAnalytics"
            responsive="sm"
            :items="tableItems"
          >
            <template #table-busy>
              <div class="text-center text-danger my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong>Loading...</strong>
              </div>
            </template>
          </b-table>
        </b-card>

        <div v-if="!isFetchingAnalytics">
          <b-row>
            <b-col cols="6">
              <LineChart
                title="Cost vs ROAS"
                :costVsRoasChart="costVsRoasChart"
              />
            </b-col>
            <b-col cols="6">
              <LineChart title="CPM vs CPC" :costVsRoasChart="cpmVsCpcChart" />
            </b-col>
          </b-row>

          <b-row>
            <b-col>
              <LineChart title="CTR vs CPC" :costVsRoasChart="ctrVscpcChart" />
            </b-col>
          </b-row>
        </div>
      </b-col>
    </div>
  </div>
</template>

<script>
import {
  BAlert,
  BLink,
  BCard,
  BRow,
  BCol,
  BContainer,
  BBadge,
  BButton,
  BTable,
  BSpinner,
  BAvatar,
  BCardTitle,
} from "bootstrap-vue";
import axios from "axios";
import { getUserToken } from "@/auth/utils";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import { $themeColors } from "@themeConfig";
import LineChart from "../Charts/ChartjsLineChart.vue";

import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";

import millify from "millify";
import moment from "moment";

export default {
  data() {
    return {
      client: null,
      errorMessage: {
        title: "",
        message: "",
      },
      rangePicker: [],
      tableItems: [],
      isFetchingAnalytics: true,
      chartColors: {
        primaryColorShade: "#836AF9",
        yellowColor: "#ffe800",
        successColorShade: "#28dac6",
        warningColorShade: "#ffe802",
        warningLightColor: "#FDAC34",
        infoColorShade: "#299AFF",
        greyColor: "#4F5D70",
        blueColor: "#2c9aff",
        blueLightColor: "#84D0FF",
        greyLightColor: "#EDF1F4",
        tooltipShadow: "rgba(0, 0, 0, 0.25)",
        lineChartPrimary: "#666ee8",
        lineChartDanger: "#ff4961",
        labelColor: "#6e6b7b",
        grid_line_color: "rgba(200, 200, 200, 0.2)",
      },
      costVsRoasChart: {
        labels: [],
        datasets: [],
      },
      cpmVsCpcChart: {
        labels: [],
        datasets: [],
      },
      ctrVscpcChart: {
        labels: [],
        datasets: [],
      },
    };
  },
  components: {
    BAlert,
    BLink,
    BCard,
    BRow,
    BCol,
    BContainer,
    BBadge,
    BButton,
    BTable,
    BSpinner,
    BAvatar,
    BCardTitle,
    ToastificationContent,
    LineChart,
    flatPickr,
  },
  methods: {
    getData() {
      let dates = this.rangePicker;

      let startDate = "";
      let endDate = "";

      if (typeof dates === "string") {
        dates = dates.split(" to ");
      }

      startDate = dates[0];
      endDate = dates[1];
      const clientID = this.client._id;
      this.isFetchingAnalytics = true;
      axios
        .post(
          `${process.env.VUE_APP_SERVER_URL}/api/analytics/get-data/google`,
          {
            clientID,
            startDate,
            endDate,
          },
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
            },
          }
        )
        .then((response) => {
          this.tableItems = response.data.tableItems;
          const chartData = response.data.chartItems;
          let costVsRoas = {
            labels: chartData.labels,
            datasets: [
              {
                data: chartData.costArray,
                label: "COST",
                borderColor: this.chartColors.lineChartDanger,
                lineTension: 0.5,
                pointStyle: "circle",
                backgroundColor: this.chartColors.lineChartDanger,
                fill: false,
                pointRadius: 1,
                pointHoverRadius: 5,
                pointHoverBorderWidth: 5,
                pointBorderColor: "transparent",
                pointHoverBorderColor: $themeColors.white,
                pointHoverBackgroundColor: this.chartColors.lineChartDanger,
                pointShadowOffsetX: 1,
                pointShadowOffsetY: 1,
                pointShadowBlur: 5,
                pointShadowColor: this.chartColors.tooltipShadow,
              },
              {
                data: chartData.ROASarray,
                label: "ROAS",
                borderColor: this.chartColors.lineChartPrimary,
                lineTension: 0.5,
                pointStyle: "circle",
                backgroundColor: this.chartColors.lineChartPrimary,
                fill: false,
                pointRadius: 1,
                pointHoverRadius: 5,
                pointHoverBorderWidth: 5,
                pointBorderColor: "transparent",
                pointHoverBorderColor: $themeColors.white,
                pointHoverBackgroundColor: this.chartColors.lineChartPrimary,
                pointShadowOffsetX: 1,
                pointShadowOffsetY: 1,
                pointShadowBlur: 5,
                pointShadowColor: this.chartColors.tooltipShadow,
              },
            ],
          };
          let cpmVsCpc = {
            labels: chartData.labels,
            datasets: [
              {
                data: chartData.CPMarray,
                label: "CPM",
                borderColor: this.chartColors.lineChartDanger,
                lineTension: 0.5,
                pointStyle: "circle",
                backgroundColor: this.chartColors.lineChartDanger,
                fill: false,
                pointRadius: 1,
                pointHoverRadius: 5,
                pointHoverBorderWidth: 5,
                pointBorderColor: "transparent",
                pointHoverBorderColor: $themeColors.white,
                pointHoverBackgroundColor: this.chartColors.lineChartDanger,
                pointShadowOffsetX: 1,
                pointShadowOffsetY: 1,
                pointShadowBlur: 5,
                pointShadowColor: this.chartColors.tooltipShadow,
              },
              {
                data: chartData.CPCarray,
                label: "CPC",
                borderColor: this.chartColors.lineChartPrimary,
                lineTension: 0.5,
                pointStyle: "circle",
                backgroundColor: this.chartColors.lineChartPrimary,
                fill: false,
                pointRadius: 1,
                pointHoverRadius: 5,
                pointHoverBorderWidth: 5,
                pointBorderColor: "transparent",
                pointHoverBorderColor: $themeColors.white,
                pointHoverBackgroundColor: this.chartColors.lineChartPrimary,
                pointShadowOffsetX: 1,
                pointShadowOffsetY: 1,
                pointShadowBlur: 5,
                pointShadowColor: this.chartColors.tooltipShadow,
              },
            ],
          };
          let ctrVsCpc = {
            labels: chartData.labels,
            datasets: [
              {
                data: chartData.CTRarray,
                label: "CTR",
                borderColor: this.chartColors.lineChartDanger,
                lineTension: 0.5,
                pointStyle: "circle",
                backgroundColor: this.chartColors.lineChartDanger,
                fill: false,
                pointRadius: 1,
                pointHoverRadius: 5,
                pointHoverBorderWidth: 5,
                pointBorderColor: "transparent",
                pointHoverBorderColor: $themeColors.white,
                pointHoverBackgroundColor: this.chartColors.lineChartDanger,
                pointShadowOffsetX: 1,
                pointShadowOffsetY: 1,
                pointShadowBlur: 5,
                pointShadowColor: this.chartColors.tooltipShadow,
              },
              {
                data: chartData.CPCarray,
                label: "CPC",
                borderColor: this.chartColors.lineChartPrimary,
                lineTension: 0.5,
                pointStyle: "circle",
                backgroundColor: this.chartColors.lineChartPrimary,
                fill: false,
                pointRadius: 1,
                pointHoverRadius: 5,
                pointHoverBorderWidth: 5,
                pointBorderColor: "transparent",
                pointHoverBorderColor: $themeColors.white,
                pointHoverBackgroundColor: this.chartColors.lineChartPrimary,
                pointShadowOffsetX: 1,
                pointShadowOffsetY: 1,
                pointShadowBlur: 5,
                pointShadowColor: this.chartColors.tooltipShadow,
              },
            ],
          };
          this.costVsRoasChart = costVsRoas;
          this.cpmVsCpcChart = cpmVsCpc;
          this.ctrVscpcChart = ctrVsCpc;
          this.isFetchingAnalytics = false;
        })
        .catch((error) => {
          console.log(error.response.data);
          this.isFetchingAnalytics = false;
        });
    },
    getClient() {
      const slug = this.$route.params.slug;
      axios
        .get(`${process.env.VUE_APP_SERVER_URL}/api/client/${slug}`, {
          headers: {
            Authorization: `Bearer ${getUserToken()}`,
          },
        })
        .then((response) => {
          if (!response.data.client.publicDashboard) {
            this.client = null;
            this.errorMessage.message =
              "Link expired, or you don't have access to view this page.";
            this.errorMessage.title = "Unauthorized";
          } else {
            this.client = response.data.client;
            this.getData();
          }
        })
        .catch((error) => {
          console.log(error);
          this.client = null;
          this.errorMessage.message = error.response.data.error;
          this.errorMessage.title = "Invalid Client!";
        });
    },
    getFormatedValue(value, type) {
      if (type === "INTEGER") {
        return millify(value);
      } else if (type === "PERCENT") {
        value = parseFloat(value);
        return value.toFixed(2) + "%";
      } else if (type === "TIME") {
        var dur = moment.duration(parseInt(value, 10), "seconds");
        var minutes = dur.minutes();
        var seconds = dur.seconds();
        return `${minutes}m ${seconds}s`;
      } else {
        return value;
      }
    },
    avatarText(value) {
      if (!value) return "";
      const nameArray = value.split(" ");
      return nameArray.map((word) => word.charAt(0).toUpperCase()).join("");
    },
    resolveClientStatusVariant(status) {
      return status ? "success" : "danger";
    },
  },
  mounted() {
    this.getClient();
    const startDate = moment().subtract(7, "days").format("YYYY-MM-DD");
    const endDate = moment().format("YYYY-MM-DD");
    this.rangePicker = [startDate, endDate];
  },
};
</script>

<style>
</style>